import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Modal from '@mui/material/Modal';
import Button from '@mui/material/Button';
import Icon from '@mui/material/Icon';
import Skeleton from '@mui/material/Skeleton';
import Fade from '@mui/material/Fade';
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';

import { scheduleSuccessIcon } from '../consts/consts';
import Details from '../../../WhiteLabel/Schedule/utils/Details';
import { useFormStore } from '../../../../stores';

const SuccessPopup = ({ open, values, onClose = Function.prototype }) => {
	const initFormDrawer = useFormStore(state => state.initFormDrawer);
	const hasForms = useFormStore(state => Boolean(state.formsByPatientId[values.patientId]?.length));
	const getFormsByPatientId = useFormStore(state => state.getFormsByPatientId);
	const [isLoaded, setIsLoaded] = useState(false);

	const { t } = useTranslation('scheduling');

	useEffect(() => {
		getFormsByPatientId({ patientId: values?.patientId }).finally(() => {
			setIsLoaded(true);
		});
	}, [getFormsByPatientId, values?.patientId]);

	return (
		<Modal open={open} sx={{ mt: 10 }} onClose={onClose}>
			<Box
				alignItems="center"
				display="flex"
				flexDirection="column"
				gap="28px"
				position="relative"
				sx={{
					maxWidth: { xs: 'calc(100% - 40px)', md: '890px' },
					width: { xs: 'calc(100% - 40px)', md: '890px' },
					height: { xs: '100%', md: !hasForms && isLoaded ? '487px' : '643px' },
					margin: { xs: '0 20px 20px 20px', md: 'auto' },
					background: '#fff',
					padding: '40px',
					boxShadow: '0px 4px 74px 0px rgba(0, 0, 0, 0.03)',
					boxSizing: 'border-box',
					borderRadius: '6px',
					overflowX: 'hidden',
				}}
			>
				<Box display="flex" justifyContent="flex-end" position="absolute" right="20px" top="16px">
					<IconButton data-testid="close" onClick={onClose}>
						<CloseIcon />
					</IconButton>
				</Box>
				<Box
					sx={{
						svg: { height: { xs: '90px', md: 'auto' }, width: { xs: '90px', md: 'auto' } },
					}}
				>
					{scheduleSuccessIcon}
				</Box>
				<Box alignItems="center" display="flex" flexDirection="column" gap="8px" textAlign="center">
					<Typography fontSize={{ xs: '28px', md: '28px' }} fontWeight={500} sx={{ color: '#101828' }}>
						{t('Appointment booked for')}{' '}
						<Typography
							component="span"
							fontSize={{ xs: '28px', md: '28px' }}
							fontWeight={500}
							sx={{ color: '#101828' }}
						>
							{values?.user || 'User'}
						</Typography>
					</Typography>
					<Typography fontSize={{ xs: '22px', md: '22px' }} sx={{ color: '#344054' }}>
						{t('Someone from the facility would get back to you')}
					</Typography>
				</Box>
				<Details
					disableNavigation
					endTime={values?.dateEnd}
					location={values?.location || 'N/A'}
					modality={values?.modality || 'N/A'}
					startTime={values?.dateStart}
					sx={{ padding: 0 }}
				/>
				{!hasForms && !isLoaded ? (
					<Box sx={{ height: '156px', width: '100%' }}>
						<Skeleton
							sx={{
								height: '100%',
								width: '100%',
								borderRadius: 4,
							}}
							variant="rectangular"
						/>
					</Box>
				) : null}
				<Fade unmountOnExit in={hasForms}>
					<Box sx={{ display: 'flex', flexDirection: 'column', gap: '28px' }}>
						<Box
							sx={{
								backgroundColor: '#FFFAEB',
								color: '#344054',
								padding: '16px 24px',
								display: 'flex',
								gap: '12px ',
							}}
						>
							<Icon>
								<ErrorOutlineOutlinedIcon sx={{ height: '24px', width: '24px' }} />
							</Icon>
							<Box
								component="p"
								sx={{
									fontSize: '18px',
									lineHeight: '28px',
									fontWeight: 400,
									margin: 0,
									fontFamily: 'Roboto',
								}}
							>
								{t(
									'Please fill out the required forms before your appointment. You can do this now or later from the appointment card on the homepage.'
								)}
							</Box>
						</Box>
						<Box
							sx={{
								display: 'flex',
								flexDirection: { xs: 'column', md: 'row' },
								justifyContent: 'center',
								gap: '18.72px',
							}}
						>
							<Button
								color="rsPrimary"
								size="small"
								sx={{
									borderRadius: '9px',
									textTransform: 'none',
									fontSize: '19px',
									borderWidth: '1.17px',
									fontFamily: 'Roboto',
									boxShadow: 'none',
									lineHeight: '28px',
								}}
								variant="outlined"
								onClick={onClose}
							>
								{t('Skip and close')}
							</Button>
							<Button
								color="rsPrimary"
								size="small"
								sx={{
									borderWidth: '1.17px',
									borderRadius: '9px',
									textTransform: 'none',
									fontSize: '19px',
									fontFamily: 'Roboto',
									fontWeight: '400',
									boxShadow: 'none',
									lineHeight: '28px',
									backgroundColor: '#42A5F5',
									padding: '12px 19px',
									boxSizing: 'border-box',
									color: '#fff',

									'&:hover': {
										backgroundColor: '#42A5F5',
										opacity: 0.9,
									},
								}}
								variant="outlined"
								onClick={() => {
									const { dateStart, id, status, type, patientId, studyId } = values;

									setTimeout(() => {
										initFormDrawer({
											date: dateStart,
											appointmentId: id,
											appointmentStatus: status,
											type,
											patientId,
											studyId,
										});
									}, 300);

									onClose();
								}}
							>
								{t('Proceed to fill out forms')}
							</Button>
						</Box>
					</Box>
				</Fade>
			</Box>
		</Modal>
	);
};

export default SuccessPopup;
