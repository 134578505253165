import React, { useState, forwardRef } from 'react';
import { useTranslation } from 'react-i18next';
import { styled } from '@mui/system';
import { useConfig, useSelector } from '@rs-core/hooks';
import { useMsal } from '@azure/msal-react';
import { InteractionStatus } from '@azure/msal-browser';
import axios from 'axios';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import InputBase from '@mui/material/InputBase';
import Button from '@mui/material/Button';
import Icon from '@mui/material/Icon';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import Modal from '@mui/material/Modal';
import { IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

import { useScheduleStore } from '../../../../stores';
import { SCHEDULE_APPOINTMENT_TIMEOUT } from '../../../../consts';
import GoBackBtn from '../utils/GoBackBtn';
import Details from '../utils/Details';
import PhoneInput from '../utils/PhoneInput';
import Timer from './Timer';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import PhoneIcon from '@worklist-2/ui/src/assets/icons/phone.svg';
import ReservationWithRejectedConsent from './ReservationWithRejectedConsent';
import { VERIFICATION_OPTIONS } from '../constants/index';

const Reservation = ({ form }, ref) => {
	const isLoading = useScheduleStore(state => state.isLoading);
	const accountExists = useScheduleStore(state => state.accountExists);
	const orgDetails = useScheduleStore(state => state.orgDetails);
	const [timeup, setTimeup] = useState(false);
	const [verificationOption, setVerificationOption] = useState(VERIFICATION_OPTIONS.EMAIL);
	const [isLoginInProgress, setIsLoginInProgress] = useState(false);
	const __config = useConfig();
	const { region, language } = useSelector();
	const { instance, inProgress } = useMsal();
	const [selectedCountryCode, setSelectedCountryCode] = useState('US');
	const [openConsentModal, setOpenConsentModal] = useState(false);
	const [isRejectedConsent, setIsRejectedConsent] = useState(false);

	const { register, getValues, watch } = form;

	// Watch for changes
	watch();

	const { t } = useTranslation('scheduling');

	const { location, modality, startTime, endTime, email, phone, healthcareServiceId } = getValues();

	const handleSignup = () => {
		const queryParameters = {
			siteName: 'PP',
			login_uri: __config.auth.omegaai.msal.patient_portal_logout_redirect_uri,
			regionSelected: region,
			languageSelected: language,
			country: selectedCountryCode,
			signUpInfo: '',
			isAccountNotExists: true,
			isAppt: true,
			location,
			modality,
			startTime,
			endTime,
		};

		const loginRequest = {
			scopes: __config.auth.omegaai.scopes,
			extraQueryParameters: queryParameters,
			redirectUri: __config.auth.omegaai.msal.patient_portal_redirect_uri,
			prompt: 'select_account',
		};

		if (inProgress === InteractionStatus.None) {
			instance.loginRedirect(loginRequest).catch(e => {
				console.error(e);
			});
		}
	};

	const handleLogin = () => {
		const isEmailLogin = verificationOption === VERIFICATION_OPTIONS.EMAIL;
		const fieldValue = isEmailLogin ? email : phone;

		const scheduleAppointmentPayload = JSON.stringify({
			start: startTime,
			end: endTime,
			internalOrganizationId: orgDetails.id,
			patientFriendlyModality: modality,
			internalHealthcareServiceId: healthcareServiceId,
			location,
		});

		sessionStorage.setItem('schedule-appointment-payload', scheduleAppointmentPayload);

		const loginHint = isEmailLogin
			? fieldValue
			: phone.replaceAll(' ', '').replaceAll('(', '').replaceAll(')', '').replaceAll('-', '');

		setIsLoginInProgress(true);

		const url = `${__config.data_sources.blume}User/validate/${loginHint.replace(
			'+',
			''
		)}?isPhoneNumber=${!isEmailLogin}`;

		axios
			.get(url)
			.then(response => {
				if (response.data.domainhint) {
					const queryParameters = {
						login_hint: loginHint,
						domain_hint: response.data.domainhint,
						siteName: 'PP',
						regionSelected: region,
						country: selectedCountryCode,
						languageSelected: language,
						isPhone: !isEmailLogin,
						deviceId: localStorage.getItem(`${email.toLocaleLowerCase()}_deviceId`),
						userAgent: encodeURIComponent(navigator.userAgent),
						isAppt: true,
						location,
						modality,
						startTime,
						endTime,
					};

					if (response.data.email && response.data.domainhint !== 'local') {
						queryParameters.login_hint = response.data.email;
					}

					const loginRequest = {
						scopes: __config.auth.omegaai.scopes,
						redirectUri: __config.auth.omegaai.msal.patient_portal_redirect_uri,
					};

					const kmsi = localStorage.getItem('kmsi');
					if (kmsi && kmsi.toUpperCase() === email.value.toUpperCase()) {
						// KMSI enabled for this user
						queryParameters.iskmsi = true;
					} else {
						loginRequest.prompt = 'select_account';
					}

					loginRequest.extraQueryParameters = queryParameters;

					if (inProgress === InteractionStatus.None) {
						instance.loginRedirect(loginRequest).catch(e => {
							console.error(e);
							setIsLoginInProgress(false);
						});
					}
				} else {
					setIsLoginInProgress(false);
					throw new Error('Invalid domainHint, please try again!');
				}
			})
			.catch(e => {
				setOpenConsentModal(true);
			});
	};

	const closeConsentModal = () => {
		setOpenConsentModal(false);
		setIsLoginInProgress(false);
	};

	if (isRejectedConsent) {
		return <ReservationWithRejectedConsent form={form} />;
	}

	return (
		<Box
			ref={ref}
			display="flex"
			flexDirection="column"
			gap="28px"
			sx={{
				maxWidth: '890px',
				margin: { xs: '0 20px 20px 20px', md: 'auto' },
				background: '#fff',
				padding: '40px',
				boxShadow: '0px 4px 74px 0px rgba(0, 0, 0, 0.03)',
			}}
		>
			<Modal
				aria-describedby="modal-modal-description"
				aria-labelledby="modal-modal-title"
				open={openConsentModal}
			>
				<Box
					sx={{
						position: 'absolute',
						top: '14%',
						left: '50%',
						transform: 'translateX(-50%)',
						width: '35%',
						minWidth: '515px',
						minHeight: '300px',
						borderRadius: '6px',
						backgroundColor: '#fff',
						display: 'flex',
						flexDirection: 'column',
						gap: '40px',
						padding: '40px',
						alignItems: 'center',
						boxSizing: 'border-box',
					}}
				>
					<IconButton
						sx={{ height: '16px', width: '16px', position: 'absolute', top: '18px', right: '18px' }}
						onClick={closeConsentModal}
					>
						<CloseIcon sx={{ color: '#00000099', height: '14px', width: '14px' }} />
					</IconButton>
					<Box
						sx={{
							display: 'flex',
							flexDirection: 'column',
							gap: '12px',
							alignItems: 'flex-start',
							width: '100%',
						}}
					>
						<Typography sx={{ fontSize: '24px', fontWeight: 500, color: '#101828' }}>
							Privacy policy
						</Typography>
						<Typography sx={{ fontSize: '16px', color: '#475467' }}>
							Do you agree to give your consent for Blume to use your profile details to create an
							account.{' '}
							<Typography
								component="a"
								href="https://blume.omegaai.com/#introduction?blur=true"
								sx={{ color: '#42A5F5', textDecoration: 'none' }}
								target="_blank"
							>
								View privacy policy
							</Typography>
						</Typography>
						<Typography
							alignItems="center"
							display="flex"
							fontSize={{ xs: '14px', md: '14px' }}
							gap="6px"
							lineHeight="20px"
							sx={{ color: '#f79009' }}
						>
							<Icon>
								<ErrorOutlineIcon />
							</Icon>
							You wont be able to modify this appointment if you dont give consent to make a Blume profile
						</Typography>
					</Box>
					<Box
						sx={{
							width: '100%',
							fontWeight: 500,
							fontSize: '16px',
							textTransform: 'uppercase',
							display: 'flex',
							gap: '10px',
							justifyContent: 'flex-end',
						}}
					>
						<Button
							color="rsPrimary"
							sx={{
								border: '1px solid #42A5F5',
								width: '172px',
								height: '42px',
								borderRadius: '4px',
							}}
							variant="outlined"
							onClick={() => setIsRejectedConsent(true)}
						>
							<Typography>Reject</Typography>
						</Button>
						<Button
							color="rsPrimary"
							data-testid="accept-button"
							sx={{
								border: '1px solid #42A5F5',
								width: '172px',
								height: '42px',
								borderRadius: '4px',
							}}
							variant="contained"
							onClick={() => handleSignup(instance, '', { isAccountNotExists: true })}
						>
							<Typography>Accept</Typography>
						</Button>
					</Box>
				</Box>
			</Modal>
			<Box>
				<GoBackBtn />
			</Box>
			<Box alignItems="center" display="flex" justifyContent="space-between">
				<Box>
					<Typography fontSize={{ xs: '16px', md: '24px' }} fontWeight={500} sx={{ color: '#101828' }}>
						{t('Appointment reserved')}
					</Typography>
					<Typography fontSize={{ xs: '14px', md: '18px' }} sx={{ color: '#344054' }}>
						{t('Register now and save your spot before the timer runs out')}
					</Typography>
				</Box>
				<Box>
					<Timer
						minutes={SCHEDULE_APPOINTMENT_TIMEOUT.minutes}
						seconds={SCHEDULE_APPOINTMENT_TIMEOUT.seconds}
						onComplete={() => setTimeup(true)}
					/>
				</Box>
			</Box>
			<Details endTime={endTime} location={location} modality={modality} startTime={startTime} />
			<Box display="flex" flexDirection="column" gap="24px">
				{/* <Typography fontSize={{ xs: '16px', md: '20px' }} sx={{ color: '#344054' }}>
					{t('Contact details')}
				</Typography>
				<Box display="flex" sx={{ gap: '20px' }}>
					<Box
						display="flex"
						sx={{
							gap: '10px',
							width: '250px',
							height: '56px',
							border:
								verificationOption === VERIFICATION_OPTIONS.PHONE
									? '2px solid #42A5F5'
									: '1px solid #D0D5DD',
							alignItems: 'center',
							cursor: 'pointer',
							borderRadius: '4px',
							paddingLeft: '18px',
							color: verificationOption === VERIFICATION_OPTIONS.PHONE ? '#42A5F5' : '#101828',
							'& path': {
								stroke: verificationOption === VERIFICATION_OPTIONS.PHONE ? '#42A5F5' : '#101828',
							},
						}}
						onClick={() => setVerificationOption(VERIFICATION_OPTIONS.PHONE)}
					>
						<PhoneIcon />
						<Typography>Use phone</Typography>
					</Box>
					<Box
						display="flex"
						sx={{
							gap: '10px',
							width: '250px',
							height: '56px',
							border:
								verificationOption === VERIFICATION_OPTIONS.EMAIL
									? '2px solid #42A5F5'
									: '1px solid #D0D5DD',
							borderRadius: '4px',
							alignItems: 'center',
							cursor: 'pointer',
							paddingLeft: '18px',
							color: verificationOption === VERIFICATION_OPTIONS.EMAIL ? '#42A5F5' : '#D0D5DD',
						}}
						onClick={() => setVerificationOption(VERIFICATION_OPTIONS.EMAIL)}
					>
						<EmailOutlinedIcon />
						<Typography>Use email</Typography>
					</Box>
				</Box> */}
				<Box display="flex" flexDirection="column" gap={{ xs: '16px', md: '20px' }}>
					{verificationOption === VERIFICATION_OPTIONS.EMAIL ? (
						<Box display="flex" flexDirection="column">
							<Typography
								component="label"
								fontSize="14px"
								fontWeight={500}
								htmlFor="schedule-email"
								lineHeight="20px"
								sx={{ marginBottom: '8px' }}
							>
								{t('Email')}
							</Typography>
							<Input data-testid="email" id="schedule-email" {...register('email')} />
						</Box>
					) : (
						<Box>
							<Typography
								component="label"
								fontSize="14px"
								fontWeight={500}
								htmlFor="schedule-phone"
								lineHeight="20px"
								sx={{ marginBottom: '8px' }}
							>
								{t('Phone number')}
							</Typography>
							<PhoneInput {...register('phone')} setSelectedCountryCode={setSelectedCountryCode} />
							{accountExists && (
								<Typography
									data-testid="account-exists-message"
									fontSize="14px"
									lineHeight="20px"
									sx={{ color: '#344054', margin: '6px 0 0 0' }}
								>
									{t(
										'An existing account for this phone number found, please enter your Blume Pin number below to continue reserving this appointment'
									)}
								</Typography>
							)}
						</Box>
					)}

					{accountExists && (
						<Box data-testid="account-exists">
							<Typography
								component="label"
								fontSize="14px"
								fontWeight={500}
								htmlFor="schedule-pin"
								lineHeight="20px"
								sx={{ marginBottom: '8px' }}
							>
								{t('Enter 6 digit pin')}
							</Typography>
							<Input
								id="schedule-pin"
								type="number"
								{...register('pin', {
									minLength: { value: 6, message: t('PIN should contain six digits') },
									maxLength: { value: 6, message: t('PIN should contain six digits only') },
									valueAsNumber: true,
								})}
							/>
						</Box>
					)}
				</Box>
			</Box>
			<Box sx={{ margin: '20px 0 0 0' }}>
				<Button
					fullWidth
					color="rsPrimary"
					data-testid="proceed-btn"
					disabled={
						((form.formState.errors.phone || !(phone || '').trim().length) &&
							verificationOption === VERIFICATION_OPTIONS.PHONE) ||
						((form.formState.errors.email || !(email || '').trim().length) &&
							verificationOption === VERIFICATION_OPTIONS.EMAIL) ||
						isLoading ||
						timeup ||
						isLoginInProgress
					}
					variant="contained"
					onClick={handleLogin}
				>
					{t('Proceed')}
				</Button>
			</Box>
		</Box>
	);
};

const Input = styled(InputBase)(
	() => `
    height: 40px;
    width: 100%;
    display: flex;
    padding: 8px 12px;
    align-items: center;
    gap: 8px;
    border-radius: 4px;
    border: 1px solid #D0D5DD;
    background: #FFFFFF;
  }
`
);

export default forwardRef(Reservation);
