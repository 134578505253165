import React, { forwardRef, useCallback, useEffect, useRef, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import ReCAPTCHA from 'react-google-recaptcha';
import moment from 'moment';

import { useConfig } from '@worklist-2/core';
import { useBooleanFlagValue } from '@rs-core/hooks/useFlags';

import { useScheduleStore } from '../../../../stores';
import Calendar from './Calendar';
import TimeSlots from './TimeSlots';

const BookSlots = ({ form }, ref) => {
	const __config = useConfig();
	const nextStep = useScheduleStore(state => state.nextStep);
	const jumpToStep = useScheduleStore(state => state.jumpToStep);
	const modalities = useScheduleStore(state => state.modalities);
	const orgTimezone = useScheduleStore(state => state.orgDetails.timezoneIANA);
	const isLoading = useScheduleStore(state => state.isLoading);
	const slots = useScheduleStore(state => state.timeslots);
	const getAvailableTimeslots = useScheduleStore(state => state.getAvailableTimeslots);
	const [date, setDate] = useState(new Date());
	const [selectedSlot, setSelectedSlot] = useState();
	const [verified, setVerified] = useState(false);
	const { getValues } = form;

	const [searchParams] = useSearchParams();
	const orgId = searchParams.get('orgId');
	const { t } = useTranslation('scheduling');
	const phoenixBlumeselfSchedulingSkipRecaptcha = useBooleanFlagValue('phoenix-blume-self-scheduling-recapcha');

	const reCaptchaRef = useRef();

	useEffect(() => {
		if (phoenixBlumeselfSchedulingSkipRecaptcha) {
			setVerified(phoenixBlumeselfSchedulingSkipRecaptcha);
		}
	}, [phoenixBlumeselfSchedulingSkipRecaptcha, searchParams]);

	useEffect(() => {
		if (!date) return;

		const DicomModalities = Array.isArray(modalities)
			? modalities?.find(({ Name }) => Name === getValues()?.modality)?.DicomModalities
			: '';

		getAvailableTimeslots({ orgId, modality: DicomModalities, date: moment(date).format('YYYY-MM-DD') });
	}, [date, getAvailableTimeslots, getValues, modalities, orgId]);

	const handleSubmit = useCallback(() => {
		const startDateTime = moment(date).startOf('day').add(selectedSlot.start, 'minutes').tz(orgTimezone, true);
		const endDateTime = moment(date).startOf('day').add(selectedSlot.end, 'minutes').tz(orgTimezone, true);

		form.setValue('startTime', startDateTime);
		form.setValue('endTime', endDateTime);
		// TODO: Check what need to be done if there are multiple Ids
		form.setValue('healthcareServiceId', selectedSlot.healthcareServices[0]);

		nextStep();
	}, [date, form, nextStep, orgTimezone, selectedSlot]);

	return (
		<Box
			ref={ref}
			sx={{
				width: 'min(650px,96%)',
				margin: '10px auto',
				fontFamily: 'ROBOTO',
				'& p': {
					margin: '5px 0px',
				},
			}}
		>
			<Box
				sx={{
					height: '80px',
					display: 'flex',
					justifyContent: 'space-between',
					alignItems: 'center',
					padding: '0px 20px',
					background: '#ffffff',
				}}
			>
				<div>
					<p>{t('Selected Order set')}</p>
					<p style={{ fontSize: 20 }}>
						<b>
							Xray Scan {t('at')} <span style={{ color: '#42A5F5' }}>Queenstreet</span>
						</b>
					</p>
				</div>
				<Button color="info" sx={{ color: '#42A5F5' }} variant="text" onClick={() => jumpToStep(0)}>
					{t('CHANGE')}
				</Button>
			</Box>
			<Box sx={{ padding: '20px', background: '#ffffff', marginTop: '20px' }}>
				<p>
					<b>{t('Select date and time')}</b>
					<p>{t('Choose a date from the calendar and a convenient timeslot')}</p>
				</p>
				<Calendar date={date} setDate={setDate} />
				<TimeSlots
					loading={isLoading}
					selectedSlot={selectedSlot}
					setSelectedSlot={setSelectedSlot}
					slots={slots}
				/>
				<Box sx={{ margin: '30px auto 10px auto', display: 'grid', placeItems: 'center' }}>
					<ReCAPTCHA
						ref={reCaptchaRef}
						sitekey={__config.patient_portal.recaptcha_verification_key}
						theme="light"
						onChange={setVerified}
					/>
					<Button
						color="info"
						data-testid="bookslot-next-btn"
						disabled={!verified || !date || !selectedSlot}
						sx={{ backgroundColor: '#42A5F5', marginTop: '20px' }}
						type="button"
						variant="contained"
						onClick={handleSubmit}
					>
						{t('BOOK APPOINTMENT')}
					</Button>
				</Box>
			</Box>
		</Box>
	);
};

export default forwardRef(BookSlots);
