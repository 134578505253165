import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import MenuItem from '@mui/material/MenuItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import CorporateFareIcon from '@mui/icons-material/CorporateFare';
import ListItemText from '@mui/material/ListItemText';
import { Tooltip } from '@mui/material';
import Typography from '@mui/material/Typography';
import InputAdornment from '@mui/material/InputAdornment';
import CircularProgress from '@mui/material/CircularProgress';

import FormAPIAutocompleteVariant from '@worklist-2/ui/src/components/FormNew/formPartials/FormFieldVariants/variants/FormAPIAutocompleteVariant';
import { useIsMobile, useIsTablet } from '@worklist-2/core';
import { menuProps } from '../../consts/consts';

import { useNewAppointmentContentV2Context } from '../../contexts/NewAppointmentContentV2ContextProvider';
import { useBooleanFlagValue } from '@rs-core/hooks/useFlags';

const Facility = () => {
	const isMobile = useIsMobile();
	const isTablet = useIsTablet();
	const phoenixBlumeOaiPatientDemographicSync = useBooleanFlagValue('phoenix-blume-oai-patient-demographic-sync');
	const phoenixBlumeShowTestOrgFacilityListOnlyForRamsoftUsers = useBooleanFlagValue(
		'phoenix-blume-show-test-org-facility-list-only-for-ramsoft-users'
	);
	const { t } = useTranslation('appointments');
	const [options, setOptions] = useState([]);

	const { isUpdateForm, isLoadingFacility, hasPendingCRs, form, facilityList, filterFacilityOptions, helperText } =
		useNewAppointmentContentV2Context();

	useEffect(() => {
		setOptions(Array.isArray(facilityList) ? facilityList : []);
	}, [facilityList]);

	return (
		<FormAPIAutocompleteVariant
			TextFieldProps={{
				placeholder: t('Select'),
				label: t('Healthcare Provider'),
				helperText,
				InputProps: {
					onFocus: () => {
						!form.getValues('scanType') &&
							form.setError('scanType', {
								type: 'manual',
								message: t('Please select a Order Set first'),
							});

						!form.getValues('userLocation') &&
							form.setError('userLocation', {
								type: 'manual',
								message: t('Please select your location first'),
							});
					},
					startAdornment: (
						<InputAdornment
							position="start"
							sx={{
								'& .MuiSvgIcon-root': {
									opacity: 1.6,
								},
							}}
						>
							<CorporateFareIcon sx={{ color: '#121212', opacity: 0.6 }} />
							{isLoadingFacility && (
								<CircularProgress
									size={30}
									sx={{
										color: '#42a5f5',
									}}
								/>
							)}
						</InputAdornment>
					),
				},
			}}
			formHook={form}
			hideClear={!form.getValues('facility')}
			name="facility"
			options={options}
			props={{
				disabled: !!(isUpdateForm || (hasPendingCRs && phoenixBlumeOaiPatientDemographicSync)),
				isOptionEqualToValue: (option, value) =>
					!value || option?.internalOrganizationID === value?.internalOrganizationID,
				getOptionLabel: option => (option?.organizationName ? option?.organizationName : ''),
				renderOption: (props, option) => {
					// Hide test orgs from the list for non-Ramsoft users
					const isTestingOrg = option?.organizationName?.startsWith('@RS-');
					const hiddenOrgIds = [20, 90846, 183160];
					const isBLUMEORGINTERNAL = option?.internalOrganizationID === 90846;

					if (
						isBLUMEORGINTERNAL ||
						(phoenixBlumeShowTestOrgFacilityListOnlyForRamsoftUsers &&
							(hiddenOrgIds.includes(option?.internalOrganizationID) || isTestingOrg))
					) {
						return null;
					}

					return option?.internalOrganizationID === 'no_facilities_available' ? (
						<li key={option?.internalOrganizationID}>
							<MenuItem
								key={option?.internalOrganizationID}
								sx={menuProps}
								value={option?.internalOrganizationID}
								onClick={() =>
									!form.getValues('scanType') &&
									form.setError('scanType', {
										type: 'manual',
										message: t('Please select a Order Set first'),
									})
								}
							>
								{t('No Facilities Available')}
							</MenuItem>
						</li>
					) : (
						<li {...props} key={option?.internalOrganizationID}>
							<MenuItem
								key={option?.internalOrganizationID}
								sx={{
									...props.MenuProps,
								}}
								value={option?.internalOrganizationID}
							>
								<ListItemAvatar
									sx={{
										marginLeft: '-25px',
									}}
								>
									<Avatar
										sx={{
											bgcolor: '#5F92DE',
										}}
									>
										<CorporateFareIcon />
									</Avatar>
								</ListItemAvatar>

								<ListItemText
									primary={
										<Tooltip title={option?.organizationName}>
											<Typography
												sx={{
													color: '#121221DE',
													fontSize: '16px',
													fontWeight: '400',
													overflow: 'hidden',
													textOverflow: 'ellipsis',
													width: '15rem',
												}}
											>
												{option?.organizationName}
											</Typography>
										</Tooltip>
									}
									secondary={
										<>
											<Tooltip
												title={`${
													option?.address + option?.distance?.toString().split('.')[0]
												} Km`}
											>
												<Typography
													display={isMobile || isTablet ? undefined : 'inline'}
													sx={{
														color: '#12121299',
														fontSize: '14px',
														fontWeight: '400',
														overflow: 'hidden',
														textOverflow: 'ellipsis',

														width: '15rem',
													}}
												>
													{option?.address}{' '}
												</Typography>
											</Tooltip>
											<Typography
												display={isMobile || isTablet ? undefined : 'inline'}
												sx={{
													color: '#121221DE',
													fontSize: '16px',
													fontWeight: '400',
												}}
											>
												{option?.distance?.toString().split('.')[0]} Km
											</Typography>
										</>
									}
									sx={{
										marginLeft: '-10px',
									}}
								/>
							</MenuItem>
						</li>
					);
				},
				filterOptions: filterFacilityOptions,
			}}
			onSearch={() => facilityList}
		/>
	);
};

export default Facility;
