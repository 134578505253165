/* eslint-disable react/prop-types */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/jsx-no-useless-fragment */
import { ChevronRight, InfoOutlined, Language, OpenInNew, Phone } from '@mui/icons-material';
import { Box, Button, Divider, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';

export const getJsonValue = (fac, property) => {
	if (!fac || !property) return 'N/A';
	let parsedValues = {};
	try {
		if (fac?.extJson) {
			parsedValues = JSON.parse(fac?.extJson);
		}
	} catch (_err) {
		parsedValues = {};
	}
	if (property === 'phone') {
		const { telecom } = parsedValues;
		if (!telecom?.length) return 'N/A';
		return telecom?.find(d => d.system === 'phone')?.value || 'N/A';
	}
	return parsedValues[property] || 'N/A';
};

const FacilityListV2 = ({ facilities = [], onSelectFacility = Function.prototype, facility, t, isTablet }) => {
	const [hasOfflineFacilities, setHasOfflineFacilities] = useState(false);
	const [expandOffline, setExpandOffline] = useState(false);

	useEffect(() => {
		setExpandOffline(facilities?.length && facilities.every(d => !d?.isAppointmentBookingAllowed));
		setHasOfflineFacilities(facilities?.some(d => !d?.isAppointmentBookingAllowed));
	}, [facilities]);

	return (
		<Box
			sx={{
				width: 'min(550px,100%)',
				maxHeight: '700px',
				pb: isTablet ? 2 : 10,
				overflowY: 'auto',
				height: 'auto',
				overflowX: 'hidden',
				mt: 1.5,
			}}
		>
			{facilities
				.filter(d => Boolean(d?.isAppointmentBookingAllowed))
				.map(fac => (
					<FacilityItem
						key={fac?.internalOrganizationID}
						fac={fac}
						isSelected={facility?.internalOrganizationID === fac?.internalOrganizationID}
						t={t}
						onClick={onSelectFacility(fac)}
					/>
				))}
			{hasOfflineFacilities ? (
				expandOffline ? (
					<>
						<Divider sx={{ mt: 2 }} variant="fullWidth" />
						<Typography
							color="#475467"
							display="block"
							fontSize={14}
							fontWeight={500}
							mb={1}
							mt={2}
							variant="p"
						>
							{t('Offline healthcare providers')}
						</Typography>
						<Box
							alignItems="center"
							border="2px solid #FEDF89"
							borderRadius="6px"
							display="flex"
							mb={1.5}
							py={1}
							sx={{ background: '#FFFAEB' }}
						>
							<InfoOutlined sx={{ mx: 1, color: '#DC6803' }} />{' '}
							<Typography
								color="#DC6803"
								flex={1}
								fontSize={13}
								fontWeight={500}
								lineHeight={1.2}
								textAlign="left"
							>
								{t(
									'These facilities doesnt support online booking through blume. Please contact the facility for more details'
								)}
							</Typography>
						</Box>
						{facilities
							.filter(d => !d?.isAppointmentBookingAllowed)
							.map(fac => (
								<FacilityItem key={fac?.internalOrganizationID} disabled fac={fac} t={t} />
							))}
					</>
				) : (
					<Box
						alignItems="center"
						border="2px solid #D0D5DD"
						borderRadius="6px"
						display="flex"
						justifyContent="space-between"
						mt={4}
						sx={{ background: '#F2F4F7' }}
					>
						<InfoOutlined sx={{ mx: 1, color: '#667085' }} />{' '}
						<Typography color="#121926" flex={1} fontSize="13px" fontWeight={500} textAlign="left">
							{t('offlineHealthcareProvidersAvailableCountDescription', {
								count: facilities.filter(d => !d?.isAppointmentBookingAllowed)?.length,
							})}
						</Typography>
						<Button
							color="primary"
							sx={{ color: 'var(--color-primary)', textTransform: 'none' }}
							variant="text"
							onClick={() => setExpandOffline(true)}
						>
							{t('View')} <ChevronRight />
						</Button>
					</Box>
				)
			) : null}
		</Box>
	);
};

export default FacilityListV2;

export const FacilityItem = ({ fac, isSelected, disabled, onClick, t }) => (
	<Box
		key={fac?.internalOrganizationID}
		data-testid={`facility${fac?.internalOrganizationID}`}
		sx={{
			borderRadius: '8px',
			paddingY: 1,
			mb: 1.4,
			display: 'flex',
			alignItems: 'start',
			cursor: !disabled && 'pointer',
			background: disabled && '#F2F4F7',
			border: isSelected ? '2.5px solid var(--color-primary)' : '1px solid #D0D5DD',
		}}
		onClick={disabled ? null : onClick}
	>
		<Box sx={{ flex: 1, mx: 1 }}>
			<Typography color="#101828" fontSize="14px" fontWeight={500}>
				{fac?.organizationName}
			</Typography>
			<Typography color="#697586" fontSize="13px" mt={0.1}>
				{fac?.address}
			</Typography>
			<Typography alignItems="center" color="#697586" display="flex" fontSize="13px" mt={0.1}>
				<Phone fontSize="small" sx={{ mr: '5px' }} />
				{getJsonValue(fac, 'phone')} <Language fontSize="small" sx={{ mx: '5px' }} /> {fac?.website || 'N/A'}{' '}
				<OpenInNew
					sx={{ ml: '5px', fontSize: 16, color: 'var(--color-primary)', opacity: 0.5 }}
					// onClick={() => window.open(fac?.website)}
				/>
			</Typography>
		</Box>
		<Button
			color="primary"
			component="a"
			href={`https://www.google.com/maps/place/${encodeURIComponent(fac?.address)}`}
			rel="noopener"
			sx={{ color: '#42A5F5', textTransform: 'none' }}
			target="_blank"
			variant="text"
		>
			{t('View in maps')}
		</Button>
	</Box>
);
