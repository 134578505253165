import React, { useState } from 'react';
import { Typography, Box, IconButton, Tooltip } from '@mui/material';
import GoogleMapReact from 'google-map-react';
import { CropFree, LocationOn, RadioButtonChecked } from '@mui/icons-material';
import { useIsMobile, useIsTablet } from '@worklist-2/core/src';

const Maps = ({ facility, mapDefaultCenter = {}, setTooltipIsOpen, showUserMarker, tooltipIsOpen }) => {
	const [mapFullScreen, setMapFullScreen] = useState(false);
	const isMobile = useIsMobile();
	const isTablet = useIsTablet();

	return (
		<Box
			sx={
				!mapFullScreen
					? {
							margin: isTablet ? '20px 0px 0px 0px' : '10px 30px 0px 30px',
							// eslint-disable-next-line no-nested-ternary
							height: isMobile ? '200px' : isTablet ? '250px' : 'calc(100vh - 400px)',
							position: 'relative',
							borderRadius: '4px',
							overflow: 'hidden',
					  }
					: {
							margin: '0px',
							position: 'absolute',
							zIndex: 1000,
							top: 0,
							left: 0,
							width: '100%',
							height: '100%',
					  }
			}
		>
			{!mapFullScreen && (
				<IconButton
					aria-label="expand"
					name="expand"
					style={{
						position: 'absolute',
						top: '10px',
						right: '10px',
						zIndex: '10',
						backgroundColor: 'white',
						'&:hover': { backgroundColor: 'rgba(0, 0, 0, 0.04)' },
					}}
					onClick={() => setMapFullScreen(true)}
				>
					<CropFree />{' '}
				</IconButton>
			)}
			<GoogleMapReact
				center={mapDefaultCenter}
				options={{ fullscreenControl: false }}
				zoom={showUserMarker ? 10 : 1}
			>
				{showUserMarker && (
					<RadioButtonChecked
						fontSize="large"
						lat={mapDefaultCenter.lat}
						lng={mapDefaultCenter.lng}
						sx={{ color: '#C480DC' }}
					/>
				)}
				{facility && (
					<IconButton lat={facility.lat} lng={facility.lng}>
						<Tooltip
							open={tooltipIsOpen}
							sx={{ minWidth: '80vw' }}
							title={
								<>
									<Typography color="inherit">{facility.organizationName}</Typography>
									<Typography color="inherit">{facility.address}</Typography>
								</>
							}
							onClose={() => setTooltipIsOpen(false)}
							onOpen={() => setTooltipIsOpen(true)}
						>
							<LocationOn
								fontSize="large"
								sx={{ color: '#42A5F5' }}
								onClick={() => setTooltipIsOpen(!tooltipIsOpen)}
							/>
						</Tooltip>
					</IconButton>
				)}
			</GoogleMapReact>
		</Box>
	);
};

export default Maps;
